<template>
  <div class="home" style="margin-top: 80px">
    <div
      v-if="loading"
      style="
        margin-top: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <v-progress-circular
        :size="50"
        color="turquoise"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <swiper
        class="swiper d-none d-sm-none d-md-block"
        ref="swiper"
        :options="mainSwiperOption"
      >
        <swiper-slide
          v-for="bannerItem in banner"
          class="mainBanner"
          v-bind:key="bannerItem.imageUrl"
        >
          <div
            class="mainBanner"
            :style="{
              'background-image': 'url(' + bannerItem.imageUrl + ')',
              'background-size': '100% 100%',
              'border-radius': '30px',
              cursor: 'pointer',
            }"
            @click="handleBannerClick(bannerItem)"
          ></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <swiper
        class="swiper d-sm-block d-md-none"
        ref="swiper"
        :options="mainSwiperOption"
      >
        <swiper-slide
          v-for="bannerItem in banner"
          class="mainBanner"
          v-bind:key="bannerItem.imageUrl"
        >
          <div
            class="mainBanner"
            :style="{
              'background-image': 'url(' + bannerItem.imageUrl + ')',
              'background-size': '100% 100%',
              'border-radius': '30px',
              cursor: 'pointer',
            }"
            @click="handleBannerClick(bannerItem)"
          ></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div v-if="!loading" style="margin-top: 40px">
      <div
        v-for="(category, index) in filterCategories"
        v-bind:key="category.id"
      >
        <div v-if="index == 6">
          <div>
            <div>
              <div style="text-align: center">
                <img
                  class="img-title"
                  src="https://zertuimages.s3.us-west-2.amazonaws.com/portadas_categorias/categoria-libros-min.png"
                />
              </div>
              <swiper class="swiper" ref="swiper" :options="swiperOption">
                <swiper-slide
                  v-for="book in dashboard.dashboardBooks"
                  v-bind:key="book.id"
                  style="padding: 10px"
                >
                  <v-card
                    v-if="!book.courseBook"
                    @click="openBook(book.id)"
                    class="zoom"
                    elevation="2"
                    :style="{
                      'background-image': 'url(' + book.imageUrl + ')',
                      'background-size': '100% 100%',
                      'padding-top': '60%' /* 1:1 Aspect Ratio */,
                      position: 'relative',
                      width: '100%',
                    }"
                  >
                    <v-btn
                      v-if="book.url"
                      elevation="2"
                      depressed
                      dark
                      style="position: absolute; bottom: 10px; right: 10px"
                      color="turquoise"
                      >Ver Libro</v-btn
                    >
                  </v-card>
                  <v-card
                    v-else-if="book.courseBook"
                    class="zoom"
                    elevation="2"
                    :style="{
                      'background-image': 'url(' + book.newImageUrl + ')',
                      'background-size': '100% 100%',
                      'padding-top': '60%' /* 1:1 Aspect Ratio */,
                      position: 'relative',
                      width: '100%',
                    }"
                    @click="goToCourse(book.id)"
                  >
                    <v-btn
                      v-if="!book.isPaidCourse"
                      elevation="2"
                      depressed
                      dark
                      style="position: absolute; bottom: 10px; right: 10px"
                      color="turquoise"
                      class="d-none d-sm-flex"
                      @click="goToCourse(book.id)"
                      >Ver Libro</v-btn
                    >
                    <v-btn
                      v-if="book.isPaidCourse"
                      elevation="2"
                      depressed
                      dark
                      class="d-none d-sm-flex"
                      style="position: absolute; bottom: 10px; right: 10px"
                      color="turquoise"
                      @click="goToCourse(book.id)"
                      >Precio: {{ book.price | toCurrency }}</v-btn
                    >
                  </v-card>
                  <h2 class="courseName">{{ book.name }}</h2>
                </swiper-slide>
                <div
                  class="swiper-button-prev"
                  style="color: purple"
                  slot="button-prev"
                ></div>
                <div
                  class="swiper-button-next"
                  style="color: purple"
                  slot="button-next"
                ></div>
              </swiper>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <img class="img-title" :src="category.titleImageUrl" />
        </div>
        <swiper class="swiper" ref="swiper" :options="swiperOption">
          <template v-if="category.certifications.length > 0">
            <swiper-slide
              v-for="certificacion in category.certifications.filter(
                (x) => x.id !== 2 || (x.id === 2 && userHasMaster)
              )"
              v-bind:key="certificacion.id"
              style="padding: 10px"
            >
              <v-card
                class="zoom"
                elevation="2"
                :style="{
                  'background-image': 'url(' + certificacion.urlPortada + ')',
                  'background-size': '100% 100%',
                  'padding-top': '60%' /* 1:1 Aspect Ratio */,
                  position: 'relative',
                  width: '100%',
                }"
                @click="$router.push('/certificacion/' + certificacion.slug)"
              >
                <v-btn
                  elevation="2"
                  depressed
                  dark
                  class="d-none d-sm-flex"
                  style="position: absolute; bottom: 10px; right: 10px"
                  color="turquoise"
                  @click="$router.push('/certificacion')"
                  >{{ (certificacion.available_purchase == false) ? 'Agotado'  : `Precio: ${certificacion.precio | toCurrency}`  }}</v-btn
                >
              </v-card>
              <h2 class="courseName">{{ certificacion.nombre }}</h2>
            </swiper-slide>
          </template>
          <swiper-slide
            v-for="course in category.courses"
            v-bind:key="course.id"
            style="padding: 10px"
          >
            <v-card
              class="zoom"
              elevation="2"
              :style="{
                'background-image': 'url(' + course.newImageUrl + ')',
                'background-size': '100% 100%',
                'padding-top': '60%' /* 1:1 Aspect Ratio */,
                position: 'relative',
                width: '100%',
              }"
              @click="goToCourse(course.id)"
            >
              <v-btn
                v-if="!course.isPaidCourse"
                elevation="2"
                depressed
                dark
                style="position: absolute; bottom: 10px; right: 10px"
                color="turquoise"
                class="d-none d-sm-flex"
                @click="goToCourse(course.id)"
                >Ver Curso</v-btn
              >
              <v-btn
                v-if="course.isPaidCourse"
                elevation="2"
                depressed
                dark
                class="d-none d-sm-flex"
                style="position: absolute; bottom: 10px; right: 10px"
                color="turquoise"
                @click="goToCourse(course.id)"
                >Precio: {{ course.price | toCurrency }}</v-btn
              >
            </v-card>
            <h2 class="courseName">{{ course.name }}</h2>
          </swiper-slide>

          <div
            class="swiper-button-prev"
            style="color: purple"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next"
            style="color: purple"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      banner: [
        {
          imageUrl:
            "https://storage.googleapis.com/zertuimages/banners/octubre24/OmegaWeb.jpg",
          mobileImageUrl:
            "https://storage.googleapis.com/zertuimages/banners/octubre24/OmegaMob.jpg"
        },
        {
          imageUrl:
            "https://storage.googleapis.com/zertuimages/banners/octubre24/TisanasWeb.jpg",
          mobileImageUrl:
            "https://storage.googleapis.com/zertuimages/banners/octubre24/TisanasMob.jpg",
          type: "shop",
          url: "79200",
        },
        {
          imageUrl:
            "https://storage.googleapis.com/zertuimages/banners/octubre24/VelasWeb.jpg",
          mobileImageUrl:
            "https://storage.googleapis.com/zertuimages/banners/octubre24/VelasMob.jpg",
          type: "product",
          url: "78951",
        },
      ],
      certificaciones: [],
      token: this.$store.state.accessToken,
      dashboard: null,
      loading: true,
      userHasMaster: false,
      mainSwiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        loop: false,
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection("horizontal");
          },
        },
      },
      swiperOptionCert: {
        pagination: {
          el: ".swiper-pagination",
        },
        slidesPerView: 5,
        spaceBetween: 5,
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          },
        },
      },
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false,
        slidesPerView: 5,
        spaceBetween: 5,
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? "vertical" : "horizontal"
            );
          },
        },
      },
    };
  },
  mounted() {
    this.loadCourses();
    this.obtenerCertificaciones();
  },
  computed: {
    filterCategories() {
      return this.dashboard.categories.filter((x) => x.name !== "Books");
    },
  },
  methods: {
    handleBannerClick(banner) {
      if (banner.type == "course") {
        this.goToCourse(banner.url);
      }
      if (banner.type == "certification") {
        this.goToCertificacion(banner.url);
      }
      if (banner.type === "product") {
        this.goToProduct(banner.url);
      }
      if (banner.type === "shop") {
        this.goToShop();
      }
    },
    abrirUrl(url) {
      window.open(url, "_blank");
    },
    openBook(bookId) {
      this.$router.push(`/book/${bookId}`);
    },
    getPayedCertifications() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        const slug = this.certificaciones.find((x) => x.id === 2).slug;
        this.axios
          .get("/certificacion/slug/" + slug, {
            headers: headers,
          })
          .then((response) => {
            this.loading = false;
            this.userHasMaster = response.data.response.pagada;
          });
      }
    },
    obtenerCertificaciones() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
        this.axios
          .get("/certificacion/list", { headers: headers })
          .then((response) => {
            console.log(response.data.response);
            this.certificaciones = response.data.response;
            this.getPayedCertifications();
          });
      } else {
        this.axios.get("/certificacion/list").then((response) => {
          console.log(response.data.response);
          this.certificaciones = response.data.response;
        });
      }
    },
    goToCourse(id) {
      this.$router.push("/course/" + id);
    },
    goToCertificacion(id) {
      this.$router.push("/certificacion/" + id);
    },
    goToProduct(id) {
      this.$router.push("/producto/" + id);
    },
    goToShop() {
      this.$router.push("/shop");
    },
    loadCourses() {
      if (this.$store.state.accessToken != null) {
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };

        this.axios.get("/main/dashboard", { headers }).then((response) => {
          this.loading = false;
          this.dashboard = response.data.response;
          //this.banner = this.dashboard.banner;
        });
      } else {
        this.axios.get("/main/dashboard").then((response) => {
          this.loading = false;
          this.dashboard = response.data.response;
          //this.banner = this.dashboard.banner;
        });
      }
    },
  },
};
</script>
<style>
.zoom {
  background-color: green;
  transition: transform 0.2s; /* Animation */
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
